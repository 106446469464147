import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StandingsConfig } from '../standings-config';
import { ResultsConfig } from '../../results/results-config/results-config';

type TCategoryPreset = {
  label: string,
  category?: number[]
};

@Component({
  selector: 'mc-standings-moderation',
  templateUrl: './standings-moderation.component.html',
  styleUrls: ['./standings-moderation.component.scss']
})
export class StandingsModerationComponent implements OnInit {

  categories: TCategoryPreset[] = [
    { label: 'All categories' },
    { label: 'Do 2L', category: [11] },
    { label: '2WD do 2.5L', category: [12] },
    { label: 'AWD do 2.5L', category: [13] },
    { label: '2WD do 3L do 350KM', category: [21] },
    { label: '2WD do 3L, 350KM+', category: [22] },
    { label: 'AWD do 3L', category: [23] },
    { label: '2WD powyżej 3L', category: [31] },
    { label: 'AWD powyżej 3L', category: [32] },
    { label: 'PRO do 2L', category: [41] },
    { label: 'PRO 2WD do 2.5L', category: [42] },
    { label: 'PRO 2WD do 3L', category: [43] },
    { label: 'PRO 2WD 3L+ i AWD 2L+', category: [44] },
    { label: 'OPEN', category: [51] },
    { label: 'OPEN 2WD do 250 KM', category: [52] },
    { label: 'OPEN 2WD 250+ KM', category: [53] },
    { label: 'OPEN AWD', category: [54] },
    { label: 'OPEN 2WD 400+ KM', category: [55] },
    { label: 'Street cars only', category: [11, 12, 13, 21, 22, 23, 31, 32] },
    { label: 'Pro cars only', category: [41, 42, 43, 44] }
  ];

  categoryIndex: number;

  @Input() standingsConfig: StandingsConfig;
  @Input() resultsConfig: ResultsConfig;

  @Output() standingsConfigChanged = new EventEmitter<StandingsConfig>();
  @Output() resultsConfigChanged = new EventEmitter<ResultsConfig>();


  set group(group: string|'') {
    this.standingsConfig.group = group === '' ? undefined : +group;
    this.emitStandingsConfig();
  }

  get group(): string {
    return this.standingsConfig.group === undefined ? '' : '' + this.standingsConfig.group;
  }

  set category(spec: TCategoryPreset) {
    this.categoryIndex = this.categories.indexOf(spec);
    this.standingsConfig.category = this.categories[this.categoryIndex].category;
    this.emitStandingsConfig();
  }

  get category(): TCategoryPreset {
    return this.categories[this.categoryIndex];
  }

  set classic(classic: string|'') {
    this.standingsConfig.classic = classic === '' ? undefined : !!+classic;
    this.emitStandingsConfig();
  }

  get classic(): string {
    return this.standingsConfig.classic === undefined ? '' : +this.standingsConfig.classic + '';
  }

  set stock(stock: string|'') {
    this.standingsConfig.stock = stock === '' ? undefined : !!+stock;
    this.emitStandingsConfig();
  }

  get stock(): string {
    return this.standingsConfig.stock === undefined ? '' : +this.standingsConfig.stock + '';
  }

  set women(women: string|'') {
    this.standingsConfig.women = women === '' ? undefined : !!+women;
    this.emitStandingsConfig();
  }

  get women(): string {
    return this.standingsConfig.women === undefined ? '' : +this.standingsConfig.women + '';
  }

  set yt(yt: string|'') {
    this.standingsConfig.yt = yt === '' ? undefined : !!+yt;
    this.emitStandingsConfig();
  }

  get yt(): string {
    return this.standingsConfig.yt === undefined ? '' : +this.standingsConfig.yt + '';
  }

  set minimumLapSeconds(n: number) {
    this.resultsConfig.minimumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get minimumLapSeconds(): number {
    return this.resultsConfig.minimumLapSeconds;
  }

  set maximumLapSeconds(n: number) {
    this.resultsConfig.maximumLapSeconds = +n;
    this.emitResultsConfig();
  }

  get maximumLapSeconds(): number {
    return this.resultsConfig.maximumLapSeconds;
  }

  set rankingLapsNumber(n: number) {
    this.resultsConfig.rankingLapsNumber = +n;
    this.emitResultsConfig();
  }

  get rankingLapsNumber(): number {
    return this.resultsConfig.rankingLapsNumber;
  }

  constructor() { }

  ngOnInit() {
    this.emitStandingsConfig();
    this.emitResultsConfig();
  }

  emitStandingsConfig() {
    this.standingsConfigChanged.emit({... this.standingsConfig});
  }

  emitResultsConfig() {
    this.resultsConfigChanged.emit({... this.resultsConfig});
  }

}
